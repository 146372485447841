import cx from 'classnames'
import { HTMLProps, MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react'
import ReactPlayer, { ReactPlayerProps } from 'react-player'
import useIntersectionObserver from '@hooks/useIntersectionObserver'

type ReactPlayerWrapperProps = ReactPlayerProps &
  Partial<{
    autoPlay: boolean
    addOverlay: boolean
    forwardEvents: Array<'click'>
    className: HTMLProps<HTMLDivElement>['className']
  }>

const ReactPlayerWrapper: React.FC<ReactPlayerWrapperProps> = ({
  autoPlay,
  addOverlay,
  forwardEvents,
  className,
  playing,
  ...props
}) => {
  const [internalPlaying, setInternalPlaying] = useState(playing ?? false)

  const headerHeight = parseInt(
    process.browser
      ? getComputedStyle(document.documentElement).getPropertyValue('--header-height') || '0rem'
      : '0rem'
  )

  const ref = useRef<HTMLDivElement | null>(null)
  const entry = useIntersectionObserver(ref, {
    threshold: 0.5,
    rootMargin: `-${headerHeight * 16}px 0px 0px 0px`,
  })

  useEffect(() => {
    setInternalPlaying(playing ?? false)
  }, [playing])

  useEffect(() => {
    setInternalPlaying((autoPlay && entry?.isIntersecting) ?? false)
  }, [autoPlay, entry?.isIntersecting])

  // Forward Events to Video Instance
  const videoOverlayRef = useCallback(
    (node) => {
      if (node) {
        if (forwardEvents?.includes('click')) {
          node.addEventListener('click', (event: MouseEvent) => {
            event.preventDefault()
            setInternalPlaying((prevInternalPlaying) => !prevInternalPlaying)
          })
        }
      }
    },
    [forwardEvents]
  )

  // Disable tabbing into iframe
  const iframe = ref.current?.getElementsByTagName('iframe')[0] ?? null
  useEffect(() => {
    if (iframe && iframe instanceof HTMLIFrameElement) {
      iframe.setAttribute('tabindex', '-1')
    }
  }, [iframe])

  return (
    <div ref={ref} className={cx('relative isolate', className)}>
      <ReactPlayer playing={internalPlaying} muted={autoPlay} {...props} />
      {addOverlay && (
        <div
          ref={videoOverlayRef}
          tabIndex={-1}
          role="button"
          aria-label="Play Video"
          className="absolute inset-0 z-10 focus-visible:ring-4 focus-visible:ring-inset"
        ></div>
      )}
    </div>
  )
}

ReactPlayerWrapper.defaultProps = {
  autoPlay: false,
  addOverlay: true,
  forwardEvents: ['click'],
}

export default ReactPlayerWrapper
